.whatsapp-icon {
    position:fixed;
    bottom:20px;
    right: 30px;
    z-index:99;
    

}
.whatsapp-icon a {
    width: 70px;
    height: 70px;
    background: linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
    display: inline-flex;
    border-radius: 50%;
    margin-right: 6px;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border: 1px solid black;
}
.whatsapp-icon a::before {
    content: "";
    width: 70px;
    height: 70px;
    position: absolute;
    background-color: #ffffff;
    border-radius: 50%;
    transform: scale(0);
	transition: 0.3s ease-in-out;
}
.whatsapp-icon a:hover::before {
    transform: scale(1);
}
.whatsapp-icon a img {
	width: 40%;
	z-index: 1;
	transition: 0.3s ease-in-out;
}
.whatsapp-icon a:hover img {
	filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}


